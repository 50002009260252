<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 15:29:17
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-21 09:48:16
-->
<template>
  <div class="product">
    <!-- 产品中心版心 -->
    <div
      class="product_banner"
      v-for="(item, index) in banner"
      :key="index"
      v-show="index == 0"
    >
      <div class="banner_img">
        <img
          class="products_img"
          :src="$utils.picturePrefix(item.image)"
          alt=""
        />
      </div>
      
    </div>

    <div class="product-tabs">
      <div class="tabs-list" :class="{ 'tabs-list-more': isMore }">
        <div
          class="tabs-item"
          v-for="(item, i) in shopTypes"
          :key="i"
          @click="changeTab(item.id)"
          :title=item.name
        >
          <span :class="{ 'tabs-item-sel': typeId == item.id }">
            {{ item.name }}
          </span>
        </div>
      </div>
      <div class="more" v-if="shopTypes.length > 5">
        <span @click="isMore = !isMore">更多</span>
      </div>
    </div>
    <div class="product-body">
      <div v-show="list.length > 0" class="product-list">
        <div
          class="product-item"
          @click="go_detail(item.id)"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="top">
            <div class="type-name text-ellipsis" :title="item.typeName">
              {{ item.typeName }}
            </div>
            <div class="title ">
              <div
                class="text-ellipsis"
                style="max-width: 150px;"
                :title="item.name"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="picture">
            <img :src="$utils.picturePrefix(item.image)" alt="" />
          </div>
          <div class="bottom">
            <span class="money">
              <span>￥</span>{{ $utils.formatMoney(item.price) }}
            </span>
          </div>
        </div>
      </div>
      <div v-show="list.length == 0" class="empty">
          <img src="../../assets/img/product/img-product-no-data.png" alt="" />
          <p>暂无产品</p>
      </div>
    </div>
    <pagination
      ref="pagination"
      :total="total"
      @change="pagin_change"
      v-if="total != 0"
    ></pagination>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination
  },
  data() {
    return {
      tab: ["产品中心", "自主品牌"],
      title: "",
      shopTypes: [],
      selShopTypeId: "",
      banner: [],
      list: [],
      num: 12,
      page: 1,
      total: 0,
      typeId: "",
      tab_index: 0,
      type: 2,
      loading: false,
      isMore: false
    };
  },
  computed: {},
  watch: {},
  created() {
    this.query_banner();
    this.getProductType();
  },
  mounted() {
    this.$refs.pagination.setData(this.page, this.num);
  },
  methods: {
    bannerHeight() {
        let width = window.innerWidth;
        let getClass = document.getElementsByClassName("banner_img")[0];
        
        // eslint-disable-next-line no-self-assign
        width < 1200 ? (width = 1200) : (width = width);
        let height = width / 1920 * 360;
        console.log(getClass,height);
        return getClass.style.height = height + "px";
    },
    //  @click  点击跳转到产品详情页
    go_detail(id) {
      this.$router.push(`/product/detail?id=${id}`);
    },
    changeTab(id) {
      this.typeId = id;
      this.page = 1;
      this.query_getList();
    },

    // 产品类型
    getProductType() {
      this.$api.getProductType().then(res => {
        let { data } = res;
        this.shopTypes = data;
        if (data.length > 0) {
          this.typeId = data[0].id;
          this.query_getList();
        }
      });
    },

    // 产品中心
    query_getList() {
      this.$api
        .getProduct({ num: this.num, page: this.page, typeId: this.typeId })
        .then(res => {
          let {
            code,
            data: { records, total }
          } = res;
          if (code == 200) {
            this.loading = false;
            this.total = total;
            this.list = records;
          }
        });
    },
    // 背景
    query_banner() {
      this.$api.getBanner({ type: this.type }).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.banner = data;
          setTimeout(() => {
            this.bannerHeight();
          }, 0);
        }
      });
    },
    // 自主品牌
    tab_handel(index) {
      this.loading = true;
      this.tab_index = index;
      if (index == 0) {
        this.typeId = 1;
      } else {
        this.typeId = 2;
      }
      this.query_getList();
    },
    // 分页
    pagin_change(page, pageSize) {
      this.page = page;
      this.num = pageSize;
      this.query_getList();
    }
  }
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
